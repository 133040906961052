<template>
  <div class=" text-end">
    <!-- APPBAR -->
    <v-app-bar fixed color="white" app elevation="0" >
      <div class="appbar-content">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon @click="drawer = true" class="d-none d-md-block"/>
          <v-btn @click="go('menu')" elevation="0" text class="btn-logo-icon ml-4 transparent-button">
            <v-img alt="SADT" src="../../assets/logo.png" width="60" height="60" />
          </v-btn>
        </div>
        <!-- <prev>{{userData.avatar}}</prev> -->
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-btn @click.stop="usuLogout" class="d-block d-md-none transparent-button">
              <v-icon color="grey darken-1">mdi-logout</v-icon>
          </v-btn>
          <v-select style="width: 200px; display: none" hide-details :items="companies" label="Empresa"
            item-text="nome_fantasia" v-model="currentCompany" outlined dense return-object />



          <!-- <div class="user-info hidden-sm-and-down">
            <v-avatar size="60" class="mr-2">
              <img :src="userData.avatar" alt="User Avatar">
            </v-avatar>
            <span class="blod_color"> <strong>Usuário Logado:</strong> {{ userData.apelido }}</span>
            <span class="blod_color" v-if="timeToExpireInSeconds > 0"><strong>Tempo para Logout:</strong> {{ timeToExpire
            }}</span>
          </div> -->
         <v-btn icon @click="openDialog" :color="hasUpdates ? 'green' : 'grey'">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" >mdi-bell</v-icon>
              </template>
              <span>{{ notificationMessage }}</span>
            </v-tooltip>
          </v-btn>

          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headline blod_color title-center">Atualizações no Sistema</v-card-title>
              <v-card-text>
                <div v-for="notification in notifications" :key="notification.id">
                  <p><b>{{ notification.message }}</b></p>
                  <p>{{ notification.details }}</p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <div class="d-flex align-center mt-5 mr-3">
            <v-avatar size="45" class="mr-2">
              <img v-if="lista.avatar" :src="lista.avatar" alt="User Avatar">
              <v-icon v-else size="45">mdi-account</v-icon>
            </v-avatar>
            <div>
              <div class="blod_color user-name"><strong>{{ lista.apelido }}</strong></div>
              <!-- <div class="blod_color user-name2"><strong>Logout: {{ timeToExpire }}</strong></div> -->
            </div>
          </div>

          
        </div>
        <!-- <v-avatar color="primary" size="30">
          <span dense class="white--text">{{ userNameInitials }}</span>
        </v-avatar> -->
      </div>
    </v-app-bar>
    <!-- DRAWER -->
    <v-navigation-drawer v-model="drawer" temporary fixed>
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-img v-if="true" alt="SADT" class="shrink mt-0 mb-4" width="90"
              @click="go('menu')"></v-img>
          </v-list-item-content>
        </v-list-item>


      </v-list>
      <v-list dense nav>
        <v-list-item @click="go(menu.link)" link v-for="menu in items" :key="menu.id">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ menu.titulo }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
        <v-list-item link @click.stop="usuLogout">
          <v-icon>mdi mdi-logout</v-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/http'
import '../../assets/css/styles.css'
// import AppConfigService from '../../service/AppConfigService'

export default {
  name: 'Drawer',
  data: () => ({
    loading: false,
    initiallyOpen: [''],
    items: [],
    btn: true,
    tree: [],
    usuario: {},
    drawer: false,
    permissoes: {},
    companies: [],
    currentCompany: null,
    timeToExpireInSeconds: 0,
    intervalId: null,
    dialog: false,
    notifications: [
      // {
      //   id: 1,
      //   message: "Criamos a GUIA SADT + OUTRAS DESPESAS:",
      //   details: "-> Ela junta em um só documento todas as guias SADT do Orçamento / Conta e acrescenta a guia Outras Despesas ao final do documento. Essa GUIA é a única que é gerada com valores e está disponivél no ícone Faturamento."
      // },
      // {
      //   id: 2,
      //   message: "Criamos a GUIA SADT LIMPA:",
      //   details: "-> Ela vem apenas no ícone do Orçamento. Ela gera guias SADT lIMPAS por especialidades / procedimento que estiverem no orçamento."
      // },
      // {
      //   id: 3,
      //   message: "Acesso aos pacientes pelo APP (Emergência):",
      //   details: "-> Os profissionais que tiverem com a função 'Emergência' ativa no seu cadastro terão acesso a todos os pacientes com plano terapêutico ativo pelo APP .Vá em Cadastro / Equipe / Selecione o Profissional e clique em 'Editar usuário' / Selecione em 'Função' a opção 'Emergência' / clique em 'Salvar'."
      // },
      // {
      //   id: 4,
      //   message: "Cadastrar equipamentos e taxas por fornecedor:",
      //   details: "-> Podemos cadastrar equipamentos e taxas por forncedor conforme o que cada fornecedor disponibiliza mediante contrato. Cadastro / Fornecedor / Editar Itens do Fornecedor."
      // },
      // {
      //   id: 5,
      //   message: "Em orçamento e faturamento inclusão de Nome na Contrante:",
      //   details: "-> Incluímos em Orçamento e Faturamento o Nome na Contratante que é o nome que a operadora quer receber alguns itens como equipamento, procedimento e taxas."
      // },
      // {
      //   id: 6,
      //   message: "Em Faturamento cadastrar data de envio do faturamento e protocolo na operadora:",
      //   details: "-> Quando gerarmos o xml, indidual ou agrupado, será aberto uma tela para que você cadastre, se quiser, a data do envio da fatura para operdoara, bem como o número do protocolo."
      // },
      // {
      //   id: 7,
      //   message: "Geração de Declaração de Atividade para equipe Multiprofissional e Declaração de Atendimento para paciente:",
      //   details: "-> Agora Você pode gerara a Declaraçõa de Atividade para o membro da equipa multi ou gerara a Declaraçõa de Atendimento para o paciente direto do cadastro. Basta selacionar o paciente ou usuário e clicar na impressora.."
      // },
      // {
      //   id: 8,
      //   message: "Exportar dados da tela para planilha de Excel:",
      //   details: "-> Em algumas telas você tem a opção de exportar os dados filtardos. Basta clicar na imagem do arquivo de excel e ela será baixada automaticamente. ."
      // },
      {
        id: 9,
        message: "Relatório Específico para Troca de GTT e TQT:",
        details: "-> Tanto na tela de Evoluções / Guia SADT, Orçamento e Faturamento podemos agora gerar os relatórios de Troca de TQT e GTT espécifico. ."
      },
      {
        id: 11,
        message: "Prontuário do Paciente:",
      details: "-> Basta expandir que temos mais informações disposniveis do paciente."
      },
      {
        id: 12,
        message: "Editar Data, Hora Inicial e Hora final de Evolução :",
        details: "-> Estamos deixando editável, tanto no Orçamento como no Faturamento as data de Evolução ."
      },
      {
        id: 13,
        message: "Forma de Pesquisa em algumas telas:",
        details: "-> Alteramos a forma de pesquisa nas telas de cadastro paciente, equipe, orçamento e faturamento. Com isso buscamos agilizar o processo."
      },
    ],
  }),
  computed: {
    ...mapGetters(['cor', 'mensagem', 'tokenAccess', 'userData', 'companyData']),
    // timeToExpire() {
    //   const seconds = this.timeToExpireInSeconds
    //   const pad = (num) => String(num).padStart(2, '0')

    //   const h = pad(Math.floor(seconds / 3600))
    //   const m = pad(Math.floor((seconds % 3600) / 60))

    //   return `${h}h:${m}mm`
    // },
    home() {
      return this.$route.name == 'login' || this.$route.name == 'home' || this.$route.name == undefined ? false : true
    },
    breadcrumbs() {
      let path = this.$route.path
      let arr = path.split('/')
      let tamanho = arr.length
      let caminho = []
      if (tamanho > 1) {
        let c = ''
        let t = ''
        for (let i = 0; i < tamanho; i++) {
          c = `/${arr[i]}`
          t = i == 0 ? 'home' : `${arr[i]}`
          caminho.push({ text: t, disabled: (tamanho == i + 1), href: c })
        }
      }
      return caminho

    },
    menus() {
      return this.items
    },
    icons() {
      return []//AppConfigService.icons()
    },
    userNameInitials() {
      const [firstName, lastName] = String(this.userData.apelido).split(' ')
      return `${String(firstName).charAt(0)}${String(lastName).charAt(0)}`.toUpperCase()
    },
    lista() {
      return this.userData || {}
    },
    hasUpdates() {
      return this.notifications.length > 0
    },
    notificationMessage() {
      return this.hasUpdates ? 'Tem novas atualizações. Clique aqui' : 'Sem Atualizações';
    },
  },
  methods: {
    ...mapActions(['usuLogout', 'updateCompany', 'updateSystemColor']),
    go(val) {
      if (this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val });
      }
    },
    fechaLoading() {
      this.$store.dispatch('btnLoading', false)
      this.$store.dispatch('cor', '')
      this.$store.dispatch('mensagem', '')
    },
    get_apps() {
      this.loading = true
      api('modulos/listar/')
        .then(res => {
          this.items = res.data
          this.loading = false
        })
    },
    usuLogout() {
      localStorage.removeItem('timeToExpireInSeconds');
      this.$store.dispatch('usuLogout');
    },
    async get_permissoes() {
      this.loading = true
      const res = await api('contas/permissoes/')
      this.permissoes = res.data[0] || {}
      this.loading = false
    },
    async getCompanies() {
      const { data } = await api.get('/multi_empresas/empresas/')
      this.companies = data

      const companyWithId1 = data.find(company => company.id === 1);
      if (companyWithId1) {
        if (!localStorage.getItem('timeToExpireInSeconds')) {
          this.timeToExpireInSeconds = companyWithId1.tempo_token * 3600;
          localStorage.setItem('timeToExpireInSeconds', this.timeToExpireInSeconds);
        }
      }

      this.currentCompany = data[0]
    },
    decrementTime() {
      if (this.timeToExpireInSeconds > 0) {
        this.timeToExpireInSeconds--;
        localStorage.setItem('timeToExpireInSeconds', this.timeToExpireInSeconds);
      } else {
        clearInterval(this.intervalId);
        localStorage.removeItem('timeToExpireInSeconds');
        this.usuLogout()
      }
    },
    processAvatar() {
      if (this.userData) {
        this.userData.avatar = this.userData.avatar.replace('http:', 'https:')
      }
    },
    openDialog() {
      if (this.hasUpdates) {
        this.dialog = true;
      }
    },
  },
  watch: {
    currentCompany: function () {
      if (this.currentCompany.cor) {
        const themes = {
          themes: this.$vuetify.theme.themes,
          color: this.currentCompany.cor
        }
        this.updateSystemColor(themes)
      }
      this.updateCompany(this.currentCompany)
    },
    userData: {
      handler() {
        this.processAvatar()
      },
      immediate: true
    }
  },
  mounted() {
    const savedTime = localStorage.getItem('timeToExpireInSeconds');
    if (savedTime) {
      this.timeToExpireInSeconds = Number(savedTime);
    }
    this.get_apps();
    this.getCompanies()
    if (this.tokenAccess !== undefined) {
      this.get_permissoes();
    }
    if (!this.intervalId) {
      this.intervalId = setInterval(this.decrementTime, 1000); // Decrementa o tempo a cada segundo
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId); // Sempre limpe o interval quando o componente for destruído para evitar vazamentos de memória
  }
};

</script>

<style scoped>
.btn-logo-icon {
  cursor: pointer;
  height: 60px !important;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.v-app-bar {
  z-index: 1000;  /* valor alto para manter acima de outros componentes */
}
.transparent-button {
    background-color: transparent !important;
    box-shadow: none !important;
}
.user-name {
  font-size:15px; /* Ajuste o tamanho da fonte conforme necessário */
  text-align: center;
}
.user-name2 {
  font-size:12px; /* Ajuste o tamanho da fonte conforme necessário */
  text-align: center;
}

.title-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

</style>
